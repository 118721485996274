import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'js-cookie'
import service from '/src/utils/request.js'

Vue.use(Vuex)
// let apiUrl = process.env.NODE_ENV === 'production' ? '/index.php/api/' : 'http://www.ykwd.com/index.php/api/'
let apiUrl = process.env.NODE_ENV === 'production' ? '/index.php/api/' : 'http://yikaowenda.test/index.php/api/'
export default new Vuex.Store({
	state: {
		apiUrl,
		qrcodeUrl: 'https://ask.xtyx.cn/qrcode/build',
		domain: 'https://ask.xtyx.cn/#/',
		baseImgUrl: 'https://ask-cdn.xtyx.cn',
		token: Cookie.get('token') || '',
		mobile: false,
		code: Cookie.get('code') || '',
		user_id: Cookie.get('user_id') || '',
		userInfo: Cookie.get('userInfo') ? JSON.parse(Cookie.get('userInfo')) : {},
		majorInfo: Cookie.get('major_info') ? JSON.parse(Cookie.get('major_info')) : {},
		tokenExpired: false,
		loginDialog: false,
		lastPath: ''
	},
	mutations: {
		SET_MAJOR(state, data) {
			Cookie.set('major_info', data);
			state.majorInfo = data
		},
		SET_CODE(state, code) {
			Cookie.set('code', code);
			state.code = code
		},
		SET_USERID(state, user_id) {
			Cookie.set('user_id', user_id);
			state.user_id = user_id
		},
		SET_TOKEN(state, token) {
			Cookie.set('token', token);
			state.token = token
		},
		SET_USERINFO(state, userInfo) {
			Cookie.set('userInfo', userInfo);
			state.userInfo = userInfo
		},
		LOGOUT(state) {
			Cookie.remove('token');
			Cookie.remove('code');
			Cookie.remove('user_id');
			Cookie.remove('userInfo');
			Cookie.remove('major_info');
		},
		SET_PLATFORM(state, info) {
			state.mobile = info
		},
		SET_TOKEN_EXPIRED(state, val) {
			state.tokenExpired = val
		},
		SET_LAST_PATH(state, val) {
			state.lastPath = val
		},
		SET_LOGIN_DIALOG(state, val) {
			state.loginDialog = val
		},
	},
	actions: {
		setMajor(context, data) {
			context.commit('SET_MAJOR', data)
		},
		setCode(context, res) {
			context.commit('SET_TOKEN', res.data.token)
			context.commit('SET_USERINFO', res.data)
			context.commit('SET_USERID', res.data.user_id)
			context.commit('SET_CODE', res.data.code)
		},
		setToken(context, params) {
			context.commit('SET_TOKEN', params)
		},
		setUserId(context, params) {
			context.commit('SET_USERID', params)
		},
		logout(context) {
			context.commit('LOGOUT')
			context.commit('SET_CODE', '')
			context.commit('SET_LAST_PATH', '')
		},
		setTokenExpired(context, val) {
			context.commit('SET_TOKEN_EXPIRED', val)
			// context.commit('SET_TOKEN', '')
		},
		setLastPath(context, val) {
			context.commit('SET_LAST_PATH', val)
		},
		setLoginDialog(context, val) {
			context.commit('SET_LOGIN_DIALOG', val)
		},
	},
	getters: {
		qrcode: state => (url) => {
			let text = encodeURIComponent(`${state.domain}${url}`)
			return `${state.qrcodeUrl}?text=${text}`
		},
		tokenExpired: state => {
			return state.tokenExpired
		},
		lastPath: state => {
			return state.lastPath
		},
		apiUrl: state => {
			return state.apiUrl
		},
		code: state => {
			return state.code
		},
		baseImgUrl: state => {
			return state.baseImgUrl
		},
		oss: state => url => {
			if (!url || url === '' || url.substr(0, 4) === 'http') {
				return url
			} else {
				return state.baseImgUrl + url
			}
		},
		token: state => {
			return state.token
		},
		user_id: state => {
			return state.user_id
		},
		isMobile: state => {
			return state.mobile
		},
		isPc: state => {
			return !state.mobile
		}
	},
	modules: {}
})