//表单验证用户名
const checkAccount = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('用户名不能为空'))
  } else {
    const reg = /^[a-z_\d]{4,16}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('由4-16位字母、数字、下划线组成'))
    }
  }
}
//表单验证微信号
const checkWechat = (rule, value, callback) => {
  if (value === '') {
    callback()
    return
  }
  const reg = /^[a-zA-Z_\d]{6,20}$/
  if (reg.test(value)) {
    callback()
  } else {
    return callback(new Error('由6-20位字母、数字、下划线组成'))
  }
}
const checkPassword = (rule, value, callback) => {
  if (value === '') {
    callback()
    return
  }
  const reg = /^[a-zA-Z_\d]{6,20}$/
  if (reg.test(value)) {
    callback()
  } else {
    return callback(new Error('由6-20位字母、数字、下划线组成'))
  }
}
//表单验证手机号
const checkPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空'))
  } else {
    const reg = /1[3456789]{1}\d{9}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确的手机号'))
    }
  }
}

//表单验证身份证号
const checkIdcard = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('身份证号不能为空'))
  } else {
    const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确的身份证号'))
    }
  }
}

//表单验证空字符串
const checkEmpty = (rule, value, callback) => {
  if ('' === value.toString().trim()) {
    return callback(new Error('请填写合法字符'))
  }
  callback()
}
//表单验证非负
const checkPositive = (rule, value, callback) => {
  if (Number(value) > 0) {
    callback()
  } else {
    return callback(new Error('请输入非负正正数'))
  }
}
//表单验证非负
const checkPositiveZ = (rule, value, callback) => {
  if (Number(value) >= 0) {
    callback()
  } else {
    return callback(new Error('请输入非负正正数'))
  }
}
export default {
  checkAccount,
  checkWechat,
  checkPhone,
  checkEmpty,
  checkPositive,
  checkPositiveZ,
  checkPassword,
  checkIdcard
}
