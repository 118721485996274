import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* 使用 element-ui */
import ElementUI from 'element-ui';
Vue.use(ElementUI)
import '/theme/index.css' // 使用自定义element主题

/* 全局引入vant组件 */
import Vant from 'vant';
import 'vant/lib/index.less';
Vue.use(Vant);

// 引入 dayjs
import dayjs from 'dayjs'
Vue.prototype.dayjs = dayjs;

// 导入自定义全局样式
import '/src/styles/theme.css'

// 导入http请求
import service from '/src/utils/request.js'
Vue.prototype.$service = service;

// 公众号相关
import mp from '/src/utils/mp.js'
Vue.prototype.mpLogin = mp.mpLogin
Vue.prototype.pageUrl = mp.pageUrl
Vue.prototype.payH5Request = mp.payH5Request
Vue.prototype.wechatH5Share = mp.wechatH5Share
// 验证
import validate from '/src/utils/validate.js'
Vue.prototype.$validate = validate

Vue.config.productionTip = process.env.NODE_ENV === 'development'

Vue.prototype.$app = new Vue()

new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	}
}).$mount('#app')