<template>
	<div id="app">
		<AppLayout>
			<div>
				<router-view :key="$route.fullPath"></router-view>
				<tabbar v-if="showTabbar"></tabbar>
			</div>
		</AppLayout>
	</div>
</template>

<script>
import AppLayout from '/src/pc/layouts/AppLayout.vue';
import tabbar from '@/components/tabbar/tabbar.vue';
export default {
	data() {
		return {};
	},
	computed: {
		showTabbar() {
			return !this.$store.getters.isPc && ['AskAdd', 'UserHome', 'Index'].includes(this.$route.name);
		}
	},
	components: { AppLayout, tabbar },
	beforeCreate() {
		this.$store.commit('SET_PLATFORM', /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent));
		if (this.$store.getters.isPc) document.documentElement.style.fontSize = '16px';
		else import('lib-flexible');
	}
};
</script>
<style lang="less">
#app {
	display: flex;
	flex-direction: column;
}
// 修复编辑器无序列表、有序列表样式
ul {
	list-style: disc inside !important;
}
ol {
	list-style: decimal inside !important;
}
.w-e-menu ul {
	list-style: none !important;
}
.w-e-menu ol {
	list-style: none !important;
}
</style>
