export default [
	// 
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/404'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '*',
		redirect: {
			name: '404'
		}
	},
	{
		path: '/',
		name: 'Index',
		component: () => import('@/pc/views/index/index'),
		meta: {
			layout: 'AppLayoutDefault',
			keepAlive: true
		}
	},
	{
		path: '/search',
		name: 'Search',
		component: () => import('@/pc/views/index/search'),
		meta: {
			layout: 'AppLayoutDefault',
			keepAlive: true
		}
	},
	{
		path: '/user/home',
		name: 'UserHome',
		component: () => import('@/pc/views/user/home'),
		meta: {
			title: '个人主页',
			layout: 'AppLayoutUser',
			keepAlive: true
		}
	},
	{
		path: '/user/edit',
		name: 'UserEdit',
		component: () => import('@/pc/views/user/edit'),
		meta: {
			title: '个人中心',
			layout: 'AppLayoutUser',
			keepAlive: true
		}
	},
	{
		path: '/ask/add',
		name: 'AskAdd',
		component: () => import('@/pc/views/ask/add'),
		meta: {
			title: '创建问答',
			layout: 'AppLayoutAsk',
			keepAlive: true
		}
	},
	{
		path: '/ask/success',
		name: 'AskSuccess',
		component: () => import('@/pc/views/ask/success'),
		meta: {
			title: '问题提交成功',
			layout: 'AppLayoutAsk',
			keepAlive: true
		}
	},
	{
		path: '/ask/detail',
		name: 'AskDetail',
		component: () => import('@/pc/views/ask/detail'),
		meta: {
			title: '问答详情',
			layout: 'AppLayoutAskDetail',
			keepAlive: true
		}
	},
]
