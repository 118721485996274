import Vue from 'vue'
import VueRouter from 'vue-router'
import mobile from './mobile.js'
import pc from './pc.js'
import mp from '/src/utils/mp.js'
import service from '/src/utils/request.js'

Vue.use(VueRouter)

/**
 * 解决报错问题：报错显示是路由重复
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err)
}
let isMobile = /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
const router = new VueRouter({
	routes: isMobile ? mobile : pc,
	mode: 'hash'
})

router.beforeEach(async (to, from, next) => {
	document.title = to.meta.title || '杏田问答'
	if (isMobile) {
		if (to.path === '/ask/detail') {
			let id = to.query.id
			let domain = window.location.origin;
			let link = `${domain}/#/ask/detail?id=${id}`
			let detailRes = await service.post('question/detail', {
				id
			});
			mp.wechatH5Share({
				title: detailRes.data.title,
				desc: detailRes.data.content,
				link
			});
		} else {
			mp.wechatH5Share();
		}
	}
	next()
})

router.afterEach((to, from, next) => {
	console.log('路由跳转成功')
	window.scrollTo(0, 0);
	if (isMobile) {
		// 设置页面背景色
		let backgroundColor = to.meta.backgroundColor || '#ffffff'
		document.querySelector('body').setAttribute('style', `background : ${backgroundColor}`);
	}
});

export default router
