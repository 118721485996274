var jweixin = ''
import service from '/src/utils/request.js'
// 页面路径
const pageUrl = function() {
	let domain = window.location.origin;
	let mode = this.$router.mode == 'hash' ? '/#' : ''

	let path = this.$route.path;
	let query = this.$route.query
	let params = Object.keys(query).map(function(key) {
		return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
	}).join("&");

	params = params ? '?' + params : ''
	return domain + mode + path + params;
}
// 授权登录
const mpLogin = function() {
	return new Promise((resolve, reject) => {
		//判断是否是微信
		let isWeixin = navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
		let url = window.location.href
		let codeIndex = url.indexOf("code");
		let code = url.substring(codeIndex + 5, url.indexOf("&"));
		if (this.$store.getters.token) {
			console.log('无需登录')
			resolve();
		} else if (codeIndex != -1 && code && code != this.$store.getters.code) {
			service.post('user_info/wxLogin', {
				code
			}).then(async res => {
				res.data.code = code
				await this.$store.dispatch('setCode', res);
				resolve();
			}).catch(() => {
				reject();
			})
		} else {
			let appid = 'wx6ea40b834cca162f';
			if (process.env.NODE_ENV === 'production') {
				let redirect_uri = encodeURIComponent(this.pageUrl());
				let href =
					`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
				window.location.href = href
			} else {
				let redirect_uri = encodeURIComponent('https://ask.xtyx.cn');
				let href =
					`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
				console.log(href)
				// window.location.href = 'http://127.0.0.1:8080/#/?code=1234'
				resolve();
			}
		}

	})
}
// 获取申请微信签名的链接
const getSignUrl = function() {
	if (process.env.NODE_ENV === 'production') {
		return location.href.split('#')[0]
	} else {
		return 'https://ask.xtyx.cn'
	}
}
const getJsSdk = function() {
	return new Promise(async (resolve, reject) => {
		if (jweixin) {
			console.log('不需要重复加载')
			resolve(jweixin)
		} else {
			console.log('加载jweixin')
			jweixin = await import('weixin-js-sdk');
			service.post('token/getSignPackage', {
				url: getSignUrl()
			}).then(verifyData => {
				jweixin.config({
					debug: false,
					appId: verifyData.data.appId,
					timestamp: verifyData.data.timestamp,
					nonceStr: verifyData.data.nonceStr,
					signature: verifyData.data.signature,
					jsApiList: [
						'chooseWXPay',
						'updateAppMessageShareData',
						'updateTimelineShareData',
						'onMenuShareTimeline',
						'onMenuShareAppMessage',
						'onMenuShareQQ',
						'onMenuShareQZone'
					]
				});
				jweixin.ready((e) => {
					console.log('jssdk.ready', e)
					resolve(jweixin)
				})
				jweixin.error((e) => {
					console.log('jssdk.error', e)
					jweixin = ''
					reject()
				})
			}).catch(() => {
				jweixin = ''
				reject()
			})
		}
	})
}
//微信公众号支付
const payH5Request = function(payData) {
	return new Promise(async (resolve, reject) => {
		let sdk = await getJsSdk();
		sdk.chooseWXPay({
			timestamp: payData.timeStamp,
			nonceStr: payData.nonceStr,
			package: payData.package,
			signType: payData.signType,
			paySign: payData.paySign,
			success: function(res) {
				// 支付成功后的回调函数
				console.log(res)
				resolve();
			},
			cancel: function(r) {
				console.log(r)
				reject({
					msg: '已取消支付'
				});
			},
			fail: function(res) {
				console.log('payfail')
				reject({
					msg: '微信支付失败'
				})
			}
		});
	})
}
// 微信分享
const wechatH5Share = function(shareData = {}) {
	console.log('设置微信分享')
	let ua = navigator.userAgent.toLowerCase();
	let isWeixin = ua.match(/MicroMessenger/i) == "micromessenger"
	if (process.env.NODE_ENV === 'development' || !isWeixin) return Promise.resolve();
	let domain = window.location.origin;
	console.log(domain)

	return new Promise(async (resolve, reject) => {
		let sdk = await getJsSdk();
		/* sdk.updateAppMessageShareData({
			title: shareData.title || `【杏田问答】医药护理考试问答平台`,
			desc: shareData.desc || '快看看大家都在问哪些问题👉',
			link: shareData.link || domain,
			imgUrl: shareData.imgUrl || `${domain}/assets/share.png`
		})
		sdk.updateTimelineShareData({
			title: shareData.title || `【杏田问答】医药护理考试问答平台`,
			link: shareData.link || domain,
			imgUrl: shareData.imgUrl || `${domain}/assets/share.png`
		})
		resolve(); */
		sdk.onMenuShareAppMessage({
			title: shareData.title || `【杏田问答】医药护理考试问答平台`,
			desc: shareData.desc || '快看看大家都在问哪些问题👉',
			link: shareData.link || domain,
			imgUrl: shareData.imgUrl || `${domain}/assets/share.png`,
			success: () => {
				if (this.$store) {
					console.log('shareSuccess')
					if (this.$store.getters.token) service.post('user_info/share_callback');
					this.$bus.$emit('shareSuccess')
				}
				resolve();
			}
		});
		sdk.onMenuShareTimeline({
			title: shareData.title || `【杏田问答】医药护理考试问答平台`,
			link: shareData.link || domain,
			imgUrl: shareData.imgUrl || `${domain}/assets/share.png`,
			success: () => {
				if (this.$store) {
					console.log('shareSuccess')
					if (this.$store.getters.token) service.post('user_info/share_callback');
					this.$bus.$emit('shareSuccess')
				}
				resolve();
			}
		});


	})
}
export default {
	pageUrl,
	mpLogin,
	payH5Request,
	wechatH5Share
}