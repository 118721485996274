import Cookie from 'js-cookie'

import store from '@/store/index.js'
import axios from 'axios';
import qs from 'qs';
import {
	Message,
	MessageBox
} from 'element-ui'
import {
	Toast,
	Dialog
} from 'vant';
import router from '@/router'


// 弹窗
function toast(message) {
	if (store.getters.isPc) {
		Message({
			message,
			type: 'error',
			duration: 3 * 1000,
			offset: 100
		})
	} else {
		Toast(message);
	}
}
axios.defaults.timeout = 5000
var service = axios.create({
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	},
	baseURL: store.getters.apiUrl
})

service.interceptors.request.use(
	config => {
		if (config.headers['Content-Type'] !== 'multipart/form-data') {
			config.data = config.data || {}
			config.data.token = store.getters.token
			if (!config.data.user_id) config.data.user_id = store.getters.user_id
			config.data = qs.stringify(config.data)
		}
		return config
	},
	error => {
		// do something with request error
		return Promise.reject(error)
	}
)
service.interceptors.response.use(
	response => {
		if (response.data.code === 1) {
			return response.data
		} else if (response.data.code === 401 || response.data.code === 10) {
			console.log('store.getters.tokenExpired', store.getters.tokenExpired)
			if (!store.getters.tokenExpired) {
				store.dispatch('setTokenExpired', true)
				if (store.getters.isPc) {
					MessageBox.confirm('您的登录信息已过期，请您重新登录~', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						showCancelButton: false,
						zIndex: 2004
					}).then(() => {
						// store.dispatch('setTokenExpired', false)
						store.dispatch('logout')
						store.dispatch('setLoginDialog', true)
					});
				} else {
					Toast.clear();
					Dialog.alert({
						title: '提示',
						message: '您的登录信息已过期，请您重新登录~',
					}).then(() => {
						store.dispatch('setTokenExpired', false)
						store.dispatch('setLastPath', router.history.current.path)
						router.replace({
							path: '/login'
						})
					});
				}
			}
			return Promise.reject(response.data.msg)
		} else {
			toast(response.data.msg)
			return Promise.reject(response.data.msg)
		}
	},
	error => {
		// toast(error.message)
		toast('网络异常')
		return Promise.reject(error)
	}
)

let post = (url, param = {}) => {
	return service.post(url, param);
}
let upload = (file) => {
	let param = new FormData()
	param.append('file', file)
	let config = {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
	return service.post('ajax/upload', param, config);
}
export default {
	post,
	upload
}