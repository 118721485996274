export default [
	// 
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/404'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '*',
		redirect: {
			name: '404'
		}
	},
	{
		path: '/',
		name: 'Index',
		component: () => import('@/views/index/index'),
		meta: {
			keepAlive: true,
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/msg',
		name: 'Msg',
		component: () => import('@/views/msg/msg'),
		meta: {
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/msg/agree',
		name: 'MsgAgree',
		component: () => import('@/views/msg/agree'),
		meta: {
			keepAlive: true,
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/search',
		name: 'Search',
		component: () => import('@/views/search/search'),
		meta: {
			title: '搜索',
			backgroundColor: '#f4f4f4'
		}
	},
	{
		path: '/auth',
		name: 'Auth',
		component: () => import('@/views/auth/auth'),
		meta: {
			title: '认证',
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/login'),
		meta: {
			title: '登录',
			backgroundColor: '#ffffff',
			keepAlive: true
		}
	},
	{
		path: '/bind',
		name: 'Bind',
		component: () => import('@/views/login/login'),
		meta: {
			title: '绑定新手机号',
			backgroundColor: '#ffffff',
			keepAlive: true
		}
	},
	{
		path: '/captcha',
		name: 'Captcha',
		component: () => import('@/views/login/captcha'),
		meta: {
			title: '短信验证码',
			backgroundColor: '#ffffff'
		}
	},
	{
		path: '/ask/detail',
		name: 'AskDetail',
		component: () => import('@/views/ask/detail'),
		meta: {
			keepAlive: true,
			title: '问答详情',
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/ask/reply',
		name: 'AskReply',
		component: () => import('@/views/ask/reply'),
		meta: {
			keepAlive: true,
			title: '问答详情',
			backgroundColor: '#ffffff'
		}
	},
	// {
	// 	path: '/user/info',
	// 	name: 'UserInfo',
	// 	component: () => import('@/views/user/info'),
	// 	meta: {
	// 		title: '个人资料',
	// 		backgroundColor: '#ffffff'
	// 	}
	// },
	{
		path: '/user/status',
		name: 'UserStatus',
		component: () => import('@/views/user/status'),
		meta: {
			keepAlive: true,
			title: '个人资料',
			backgroundColor: '#ffffff'
		}
	},
	{
		path: '/user/edit',
		name: 'UserEdit',
		component: () => import('@/views/user/edit'),
		meta: {
			keepAlive: true,
			title: '个人资料',
			backgroundColor: '#f4f4f4'
		}
	},
	{
		path: '/user/home',
		name: 'UserHome',
		component: () => import('@/views/user/home'),
		meta: {
			keepAlive: true,
			title: '个人主页',
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/ask/add',
		name: 'AskAdd',
		component: () => import('@/views/ask/add'),
		meta: {
			title: '创建问答',
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/integral',
		name: 'Integral',
		component: () => import('@/views/integral/integral'),
		meta: {
			title: '积分',
			backgroundColor: '#ffffff'
		}
	},
	{
		path: '/integral/rule',
		name: 'IntegralRule',
		component: () => import('@/views/integral/rule'),
		meta: {
			title: '活动规则',
			backgroundColor: '#ffffff'
		}
	},
	{
		path: '/income',
		name: 'Income',
		component: () => import('@/views/integral/income'),
		meta: {
			title: '答题收入',
			backgroundColor: '#ffffff'
		}
	},
	{
		path: '/income/rule',
		name: 'IncomeRule',
		component: () => import('@/views/integral/rule'),
		meta: {
			title: '活动规则',
			backgroundColor: '#ffffff'
		}
	},
	{
		path: '/history',
		name: 'History',
		component: () => import('@/views/history/history'),
		meta: {
			title: '历史记录',
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/pay',
		name: 'Pay',
		component: () => import('@/views/pay'),
		meta: {
			title: '确认支付',
			backgroundColor: '#f3f3f5'
		}
	},
	{
		path: '/agreement',
		name: 'Agreement',
		component: () => import('@/views/login/agreement'),
		meta: {
			title: '服务协议',
			backgroundColor: '#ffffff',
			keepAlive: true
		}
	},
	{
		path: '/policy',
		name: 'Policy',
		component: () => import('@/views/login/policy'),
		meta: {
			title: '隐私政策',
			backgroundColor: '#ffffff',
			keepAlive: true
		}
	}

]
