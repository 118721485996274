<template>
	<div v-if="$route.meta.keepAlive">
		<keep-alive>
			<component :is="layout"><slot /></component>
		</keep-alive>
	</div>
	<div v-else>
		<component :is="layout"><slot /></component>
	</div>
</template>

<script>
// layout布局参考自：https://juejin.cn/post/6940555448198103070
const defaultLayout = 'AppLayoutNone';
export default {
	name: 'AppLayout',
	computed: {
		layout() {
			const layout = this.$route.meta.layout || defaultLayout;
			return () => import(`/src/pc/layouts/${layout}.vue`);
		}
	}
};
</script>
