<template>
	<van-tabbar v-model="active" active-color="#03d3bc" inactive-color="#333333">
		<van-tabbar-item icon="wap-home-o" :to="{ name: 'Index' }" replace>首页</van-tabbar-item>
		<van-tabbar-item :to="{ name: 'AskAdd' }" replace>
			<template #icon="props">
				<!-- {{ props.active }} -->
				<van-button type="primary" icon="edit" size="normal" round style="height: 32px !important;">去提问</van-button>
			</template>
		</van-tabbar-item>
		<van-tabbar-item icon="user-o" :to="{ name: 'UserHome' }" replace>我的</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			active: 0
		};
	},
	created() {
		// Index AskAdd UserHome
		console.error(this.$route.name);
		this.setActive();
	},
	methods: {
		setActive() {
			switch (this.$route.name) {
				case 'AskAdd':
					this.active = 1;
					break;
				case 'UserHome':
					this.active = 2;
					break;
				case 'Index':
				default:
					this.active = 0;
					break;
			}
		}
	}
};
</script>

<style></style>
